import React from 'react';
import ThreeScene from './ThreeScene';

function App() {
  return (
    <div><div className='bk'>
      {/* Other components */}
      <ThreeScene />
    </div>
      </div>
  );
}

export default App;
