import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';

function ThreeScene() {
  const sceneRef = useRef(null);

  useEffect(() => {
    let scene, camera, cloudParticles = [], flash, renderer;

    function init() {
      scene = new THREE.Scene();
      camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 1000);
	camera.position.z = 1;
	camera.rotation.x = 1.16;
	camera.rotation.y = -0.12;
	camera.rotation.z = 0.27;

	const ambient = new THREE.AmbientLight(0x555555);
	scene.add(ambient);

	const directionalLight = new THREE.DirectionalLight(0xffeedd);
	directionalLight.position.set(0, 1, 0);
	scene.add(directionalLight);

	flash = new THREE.PointLight(0x062d89, 30, 500, 1.7);
	flash.position.set(200, 300, 100);
	scene.add(flash);

	renderer = new THREE.WebGLRenderer();
	scene.fog = new THREE.FogExp2(0x11111f, 0.002);
	renderer.setClearColor(scene.fog.color);
	renderer.setSize(window.innerWidth, window.innerHeight);
	document.body.appendChild(renderer.domElement);

	let loader = new THREE.TextureLoader();
	loader.load("https://assets.codepen.io/682745/cloud2.png", function(texture) {
		const cloudGeo = new THREE.PlaneBufferGeometry(500, 500);
		const cloudMaterial = new THREE.MeshLambertMaterial({
			map: texture,
			transparent: true
		});

		for (let i = 0; i < 25; i++) {
			let cloud = new THREE.Mesh(cloudGeo, cloudMaterial);
			cloud.position.set(
				Math.random() * 800 - 400,
				500,
				Math.random() * 500 - 450
			);
			cloud.rotation.x = 1.16;
			cloud.rotation.y = -0.12;
			cloud.rotation.z = Math.random() * 360;
			cloud.material.opacity = 1;
			cloudParticles.push(cloud);
			scene.add(cloud);
		}
		animate();
	});
    }

    function animate() {
      cloudParticles.forEach(i => {
		i.rotation.z -= 0.002;
	});

	if (Math.random() > 0.96 || flash.power > 100) {
		if (flash.power < 100)
			flash.position.set(
				Math.random() * 400,
				300 + Math.random() * 200,
				100
			);
		flash.power = 50 + Math.random() * 250;
	}

	renderer.render(scene, camera);
	requestAnimationFrame(animate);
    }

    init();
    animate();
  }, []);

  return <div ref={sceneRef} />;
}

export default ThreeScene;